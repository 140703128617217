import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      </div>
      <p>seero OÜ - Reg nr: 16849708 - Tartu mnt 67/1-13b 10115 Tallinn Estonia - E-mail: getseero@gmail.com</p>
    </div>
  );
}

export default App;
